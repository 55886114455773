import React from 'react'
import './PNF.css'
const PNF = () => {
    return (
        <div className="pnf_body">
<div className="pnf_a" href="http://www.thedresscounter.com" target="_blank">
  <header class="top-header">
</header>

<div>
  <div class="starsec"></div>
  <div class="starthird"></div>
  <div class="starfourth"></div>
  <div class="starfifth"></div>
</div>


<div class="lamp__wrap">
  <div class="lamp">
    <div class="cable"></div>
    <div class="cover"></div>
    <div class="in-cover">
      <div class="bulb"></div>
    </div>
    <div class="light"></div>
  </div>
</div>
<section class="error">
  <div class="error__content">
    <div class="error__message messages">
      <h1 class="message__titles">Page Not Found</h1>
      <p class="message__texts">We're sorry, the page you were looking for isn't found here. The link you followed may either be broken or no longer exists. Please try again, or take a look at our.</p>
    </div>
  
  </div>

</section>

  </div>

        </div>


    )
}

export default PNF;